<template>
  <RouterView
    v-slot="{ Component }"
    :class="{ 'pb-20': layoutName === 'default' }"
    class="min-h-svh bg-primary"
  >
    <ConfigurationInitializer v-if="!skipInitialization" />
    <Component :is="layoutComponent[layoutName]">
      <Component :is="Component" v-if="appIsReady" :key="appUpdateKey" />
    </Component>
    <LoadingApp v-if="showGlobalPreloader" />
  </RouterView>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onErrorCaptured, provide, type Ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import { ConfigurationInitializer, LoadingApp } from '@/3_widgets/Configuration'
import { useConfiguration } from '@/5_entities/Configuration'
import { AbortError } from '@/6_shared/api'
import { appMessenger } from '@/6_shared/lib'
import DefaultLayout from './ui/layout/default.vue'
import EmptyLayout from './ui/layout/empty.vue'
import ErrorLayout from './ui/layout/error.vue'

const route = useRoute()

const layoutName = computed((): string => {
  if (!route.name) return ''

  return route.meta.layout ? (route.meta.layout as string) : 'default'
})

const layoutComponent: Record<
  string,
  typeof DefaultLayout | typeof EmptyLayout | typeof ErrorLayout
> = {
  default: DefaultLayout,
  empty: EmptyLayout,
  error: ErrorLayout
}

const skipInitialization = computed(() => !layoutName.value || layoutName.value === 'error')

const { appUpdateKey, globalLoading, showGlobalPreloader, appIsReady } =
  storeToRefs(useConfiguration())

provide<Ref<boolean>>('globalLoading', globalLoading)

const onErrorCapturedHandler = (error: Error) => {
  console.log(error.message)
  if (error instanceof AbortError) return

  if (error?.message) {
    toast.error(error.message)
  }
}

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  onErrorCapturedHandler(event.reason)
})

onErrorCaptured(onErrorCapturedHandler)

window.onbeforeunload = () => appMessenger.close()
</script>
